import React, { useState } from 'react';
import { Send, Loader2, CheckCircle2 } from 'lucide-react';
import emailjs from '@emailjs/browser';

interface FormData {
  from_name: string;
  from_email: string;
  message: string;
}

const EMAILJS_SERVICE_ID = 'service_5oc6cg8';
const EMAILJS_TEMPLATE_ID = 'template_1g76r2n';
const EMAILJS_PUBLIC_KEY = 'jMFpvXdQYpwP1y5lq';

export default function Contact() {
  const [formData, setFormData] = useState<FormData>({
    from_name: '',
    from_email: '',
    message: ''
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState<{
    type: 'success' | 'error' | null;
    message: string | null;
  }>({ type: null, message: null });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitStatus({ type: null, message: null });

    try {
      // Envoi du message
      const result = await emailjs.send(
        EMAILJS_SERVICE_ID,
        EMAILJS_TEMPLATE_ID,
        {
          ...formData,
          subject: 'Nouveau message de contact SoundPods',
          is_contact_form: true
        },
        EMAILJS_PUBLIC_KEY
      );

      if (result.status !== 200) {
        throw new Error('Erreur lors de l\'envoi du message');
      }

      // Email de confirmation à l'utilisateur
      await emailjs.send(
        EMAILJS_SERVICE_ID,
        EMAILJS_TEMPLATE_ID,
        {
          to_email: formData.from_email,
          from_name: 'SoundPods',
          subject: 'Confirmation de réception de votre message',
          message: `
Bonjour ${formData.from_name},

Nous avons bien reçu votre message et nous vous en remercions.
Notre équipe vous répondra dans les plus brefs délais.

Cordialement,
L'équipe SoundPods
          `,
          is_confirmation: true
        },
        EMAILJS_PUBLIC_KEY
      );

      setSubmitStatus({
        type: 'success',
        message: 'Votre message a été envoyé avec succès ! Vous recevrez une confirmation par email.'
      });
      
      setFormData({
        from_name: '',
        from_email: '',
        message: ''
      });
    } catch (error) {
      console.error('Erreur lors de l\'envoi:', error);
      setSubmitStatus({
        type: 'error',
        message: 'Une erreur est survenue lors de l\'envoi du message. Veuillez réessayer.'
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  return (
    <div className="max-w-2xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <h1 className="text-3xl font-bold text-center text-gray-900 mb-8">
        Contactez-nous
      </h1>

      {submitStatus.type && (
        <div
          className={`mb-6 p-4 rounded-lg flex items-center ${
            submitStatus.type === 'success' 
              ? 'bg-green-50 text-green-800' 
              : 'bg-red-50 text-red-800'
          }`}
        >
          {submitStatus.type === 'success' && (
            <CheckCircle2 className="h-5 w-5 mr-2 text-green-400" />
          )}
          {submitStatus.message}
        </div>
      )}

      <div className="bg-white rounded-lg shadow-sm p-6">
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label
              htmlFor="from_name"
              className="block text-sm font-medium text-gray-700"
            >
              Nom
            </label>
            <input
              type="text"
              id="from_name"
              name="from_name"
              value={formData.from_name}
              onChange={handleChange}
              required
              minLength={2}
              maxLength={100}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              disabled={isSubmitting}
            />
          </div>

          <div>
            <label
              htmlFor="from_email"
              className="block text-sm font-medium text-gray-700"
            >
              Email
            </label>
            <input
              type="email"
              id="from_email"
              name="from_email"
              value={formData.from_email}
              onChange={handleChange}
              required
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              disabled={isSubmitting}
            />
          </div>

          <div>
            <label
              htmlFor="message"
              className="block text-sm font-medium text-gray-700"
            >
              Message
            </label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              rows={4}
              required
              minLength={10}
              maxLength={1000}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              disabled={isSubmitting}
            ></textarea>
          </div>

          <button
            type="submit"
            disabled={isSubmitting}
            className="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {isSubmitting ? (
              <>
                <Loader2 className="h-5 w-5 mr-2 animate-spin" />
                Envoi en cours...
              </>
            ) : (
              <>
                <Send className="h-5 w-5 mr-2" />
                Envoyer le message
              </>
            )}
          </button>
        </form>
      </div>
    </div>
  );
}