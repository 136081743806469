import React, { useState } from 'react';
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useCart } from '../context/CartContext';
import { AlertTriangle, Loader2 } from 'lucide-react';
import type { CustomerInfo } from './cart/CustomerInfoForm';

interface PayPalCheckoutButtonProps {
  customerInfo: CustomerInfo;
  total: number;
  subtotal: number;
  shipping: number;
  discount: number;
}

export default function PayPalCheckoutButton({ 
  customerInfo, 
  total,
  subtotal,
  shipping,
  discount 
}: PayPalCheckoutButtonProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { clearCart } = useCart();
  const [{ isInitial, isPending, isRejected }] = usePayPalScriptReducer();
  const [error, setError] = useState<string | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);

  if (isInitial || isPending) {
    return (
      <div className="flex items-center justify-center space-x-2 bg-gray-100 rounded-md h-[55px]">
        <Loader2 className="h-5 w-5 animate-spin text-gray-500" />
        <span className="text-sm text-gray-500">Chargement du service de paiement...</span>
      </div>
    );
  }

  if (isRejected) {
    return (
      <div className="bg-red-50 border border-red-200 rounded-md p-4 flex items-center">
        <AlertTriangle className="h-5 w-5 text-red-400 mr-2" />
        <p className="text-sm text-red-700">
          Le service de paiement est temporairement indisponible. Veuillez réessayer plus tard.
        </p>
      </div>
    );
  }

  const createOrder = async (data: any, actions: any) => {
    try {
      setError(null);
      setIsProcessing(true);

      return actions.order.create({
        purchase_units: [{
          amount: {
            value: total.toFixed(2),
            currency_code: "EUR",
            breakdown: {
              item_total: {
                value: subtotal.toFixed(2),
                currency_code: "EUR"
              },
              shipping: {
                value: shipping.toFixed(2),
                currency_code: "EUR"
              },
              discount: {
                value: discount.toFixed(2),
                currency_code: "EUR"
              }
            }
          },
          description: t('product.title'),
          shipping: {
            name: {
              full_name: `${customerInfo.firstName} ${customerInfo.lastName}`
            },
            address: {
              address_line_1: customerInfo.address,
              admin_area_2: customerInfo.city,
              postal_code: customerInfo.postalCode,
              country_code: customerInfo.country
            }
          }
        }],
        application_context: {
          shipping_preference: "SET_PROVIDED_ADDRESS",
          user_action: "PAY_NOW"
        }
      });
    } catch (err) {
      console.error('Error creating PayPal order:', err);
      setError('Une erreur est survenue lors de la création de la commande');
      return null;
    } finally {
      setIsProcessing(false);
    }
  };

  const handleApprove = async (data: any, actions: any) => {
    try {
      setIsProcessing(true);
      const order = await actions.order.capture();
      const orderId = `SP${Date.now()}`;
      clearCart();
      navigate('/success', {
        state: {
          orderId,
          paymentMethod: 'paypal',
          customerInfo,
          subtotal,
          shipping,
          discount,
          total
        }
      });
    } catch (error) {
      console.error('Payment processing error:', error);
      setError(t('cart.paymentError'));
    } finally {
      setIsProcessing(false);
    }
  };

  const handleError = (err: any) => {
    console.error('PayPal Error:', err);
    setError(t('cart.paymentError'));
    setIsProcessing(false);
  };

  return (
    <div className="space-y-4">
      {error && (
        <div className="bg-red-50 border border-red-200 rounded-md p-4">
          <p className="text-sm text-red-700">{error}</p>
        </div>
      )}
      <PayPalButtons
        style={{
          layout: "vertical",
          shape: "rect",
          label: "pay",
          height: 55
        }}
        disabled={isProcessing}
        createOrder={createOrder}
        onApprove={handleApprove}
        onError={handleError}
      />
    </div>
  );
}