import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomerInfoForm, { CustomerInfo } from './CustomerInfoForm';

interface CheckoutFormProps {
  customerInfo: CustomerInfo;
  formErrors: Record<string, boolean>;
  showPaymentButtons: boolean;
  onCustomerInfoChange: (info: CustomerInfo) => void;
  onSubmit: (e: React.FormEvent) => void;
}

export default function CheckoutForm({
  customerInfo,
  formErrors,
  showPaymentButtons,
  onCustomerInfoChange,
  onSubmit
}: CheckoutFormProps) {
  const { t } = useTranslation();

  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <h2 className="text-lg font-semibold text-gray-900 mb-6">
        {t('checkout.title')}
      </h2>
      <form onSubmit={onSubmit} className="space-y-6">
        <CustomerInfoForm
          customerInfo={customerInfo}
          onChange={onCustomerInfoChange}
          errors={formErrors}
          isSubmitting={false}
        />
        {!showPaymentButtons && (
          <button
            type="submit"
            className="w-full bg-blue-600 text-white py-3 px-6 rounded-md hover:bg-blue-700 transition duration-200"
          >
            {t('checkout.continue')}
          </button>
        )}
      </form>
    </div>
  );
}