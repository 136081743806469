import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import Product from './pages/Product';
import Cart from './pages/Cart';
import Contact from './pages/Contact';
import Success from './pages/Success';
import FAQ from './pages/FAQ';
import { CartProvider } from './context/CartContext';

const paypalOptions = {
  "client-id": "test",
  currency: "EUR",
  components: "buttons",
  "disable-funding": "paylater",
  "enable-funding": "card,paypal",
  intent: "capture"
};

function App() {
  return (
    <PayPalScriptProvider options={paypalOptions}>
      <BrowserRouter>
        <CartProvider>
          <div className="flex flex-col min-h-screen bg-gray-50">
            <Navbar />
            <main className="flex-grow">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/product" element={<Product />} />
                <Route path="/cart" element={<Cart />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/success" element={<Success />} />
                <Route path="/faq" element={<FAQ />} />
              </Routes>
            </main>
            <Footer />
          </div>
        </CartProvider>
      </BrowserRouter>
    </PayPalScriptProvider>
  );
}

export default App;