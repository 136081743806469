import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Check, Package, ArrowLeft } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { sendOrderConfirmation } from '../services/emailService';
import { useCart } from '../context/CartContext';
import { PRODUCT_PRICE } from '../config/constants';

interface LocationState {
  orderId?: string;
  paymentMethod?: 'paypal' | 'stripe';
  customerInfo?: any;
  items?: any[];
  subtotal?: number;
  shipping?: number;
  discount?: number;
  total?: number;
}

export default function Success() {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { clearCart } = useCart();
  const state = location.state as LocationState;

  useEffect(() => {
    if (!state?.orderId) {
      navigate('/', { replace: true });
      return;
    }

    const sendConfirmation = async () => {
      try {
        console.log('Preparing to send order confirmation...', {
          orderId: state.orderId,
          customerEmail: state.customerInfo?.email
        });

        await sendOrderConfirmation({
          orderId: state.orderId,
          customerInfo: state.customerInfo,
          items: [{
            name: 'SoundPods Pro',
            quantity: 1,
            price: PRODUCT_PRICE
          }],
          subtotal: state.subtotal || PRODUCT_PRICE,
          shipping: state.shipping || 4.90,
          discount: state.discount || 0,
          total: state.total || PRODUCT_PRICE + 4.90,
          paymentMethod: state.paymentMethod || 'stripe'
        });

        console.log('Order confirmation sent successfully');
      } catch (error) {
        console.error('Failed to send order confirmation:', error);
      }
    };

    if (state.customerInfo && state.orderId) {
      sendConfirmation();
    }

    clearCart();
  }, [navigate, state, clearCart]);

  if (!state?.orderId) {
    return null;
  }

  return (
    <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
      <div className="bg-white rounded-lg shadow-lg p-8">
        <div className="text-center">
          <div className="inline-flex items-center justify-center w-16 h-16 mb-6 rounded-full bg-green-100">
            <Check className="h-8 w-8 text-green-600" />
          </div>
          <h1 className="text-3xl font-bold text-gray-900 mb-4">
            {t('success.title')}
          </h1>
          <p className="text-gray-600 mb-8">
            {t('success.message')}
          </p>

          <div className="bg-gray-50 rounded-lg p-6 mb-8">
            <div className="flex items-center justify-center mb-4">
              <Package className="h-6 w-6 text-blue-600 mr-2" />
              <span className="font-medium">{t('success.orderDetails')}</span>
            </div>
            <p className="text-sm text-gray-600 mb-2">
              {t('success.orderNumber')}: <span className="font-medium">{state.orderId}</span>
            </p>
            <p className="text-sm text-gray-600">
              {t('success.paymentMethod')}: <span className="font-medium">
                {state.paymentMethod === 'paypal' ? 'PayPal' : 'Carte bancaire'}
              </span>
            </p>
          </div>

          <div className="space-y-4">
            <p className="text-gray-600">
              {t('success.emailConfirmation')}
            </p>
            <button
              onClick={() => navigate('/')}
              className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
            >
              <ArrowLeft className="h-5 w-5 mr-2" />
              {t('success.backToHome')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}