import React from 'react';
import { useTranslation } from 'react-i18next';

export interface CustomerInfo {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  address: string;
  postalCode: string;
  city: string;
  country: string;
}

interface CustomerInfoFormProps {
  customerInfo: CustomerInfo;
  onChange: (info: CustomerInfo) => void;
  errors?: Record<string, boolean>;
  isSubmitting: boolean;
}

export default function CustomerInfoForm({
  customerInfo,
  onChange,
  errors = {},
  isSubmitting
}: CustomerInfoFormProps) {
  const { t } = useTranslation();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    onChange({ ...customerInfo, [name]: value });
  };

  const getInputClassName = (fieldName: string) => `
    mt-1 block w-full rounded-md shadow-sm
    ${errors[fieldName]
      ? 'border-red-300 text-red-900 placeholder-red-300 focus:border-red-500 focus:ring-red-500'
      : 'border-gray-300 focus:border-blue-500 focus:ring-blue-500'
    }
  `;

  return (
    <div className="space-y-4">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">
            {t('checkout.firstName')} *
          </label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            value={customerInfo.firstName}
            onChange={handleChange}
            required
            className={getInputClassName('firstName')}
            disabled={isSubmitting}
          />
          {errors.firstName && (
            <p className="mt-1 text-sm text-red-600">Ce champ est requis</p>
          )}
        </div>
        <div>
          <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">
            {t('checkout.lastName')} *
          </label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            value={customerInfo.lastName}
            onChange={handleChange}
            required
            className={getInputClassName('lastName')}
            disabled={isSubmitting}
          />
          {errors.lastName && (
            <p className="mt-1 text-sm text-red-600">Ce champ est requis</p>
          )}
        </div>
      </div>

      <div>
        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
          {t('checkout.email')} *
        </label>
        <input
          type="email"
          id="email"
          name="email"
          value={customerInfo.email}
          onChange={handleChange}
          required
          className={getInputClassName('email')}
          disabled={isSubmitting}
        />
        {errors.email && (
          <p className="mt-1 text-sm text-red-600">Email invalide</p>
        )}
      </div>

      <div>
        <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
          {t('checkout.phone')} *
        </label>
        <input
          type="tel"
          id="phone"
          name="phone"
          value={customerInfo.phone}
          onChange={handleChange}
          required
          className={getInputClassName('phone')}
          disabled={isSubmitting}
        />
        {errors.phone && (
          <p className="mt-1 text-sm text-red-600">Numéro de téléphone invalide</p>
        )}
      </div>

      <div>
        <label htmlFor="country" className="block text-sm font-medium text-gray-700">
          {t('checkout.country')} *
        </label>
        <select
          id="country"
          name="country"
          value={customerInfo.country}
          onChange={handleChange}
          required
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          disabled={isSubmitting}
        >
          <option value="FR">France</option>
          {['BE', 'CH', 'DE', 'ES', 'IT', 'LU', 'NL'].map((country) => (
            <option key={country} value={country}>
              {new Intl.DisplayNames(['fr'], { type: 'region' }).of(country)}
            </option>
          ))}
        </select>
      </div>

      <div>
        <label htmlFor="address" className="block text-sm font-medium text-gray-700">
          {t('checkout.address')} *
        </label>
        <input
          type="text"
          id="address"
          name="address"
          value={customerInfo.address}
          onChange={handleChange}
          required
          className={getInputClassName('address')}
          disabled={isSubmitting}
          placeholder="Numéro et nom de rue"
        />
        {errors.address && (
          <p className="mt-1 text-sm text-red-600">Adresse invalide</p>
        )}
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <label htmlFor="postalCode" className="block text-sm font-medium text-gray-700">
            {t('checkout.postalCode')} *
          </label>
          <input
            type="text"
            id="postalCode"
            name="postalCode"
            value={customerInfo.postalCode}
            onChange={handleChange}
            required
            className={getInputClassName('postalCode')}
            disabled={isSubmitting}
          />
          {errors.postalCode && (
            <p className="mt-1 text-sm text-red-600">Code postal invalide</p>
          )}
        </div>
        <div>
          <label htmlFor="city" className="block text-sm font-medium text-gray-700">
            {t('checkout.city')} *
          </label>
          <input
            type="text"
            id="city"
            name="city"
            value={customerInfo.city}
            onChange={handleChange}
            required
            className={getInputClassName('city')}
            disabled={isSubmitting}
          />
          {errors.city && (
            <p className="mt-1 text-sm text-red-600">Ville invalide</p>
          )}
        </div>
      </div>
    </div>
  );
}