// API Configuration
export const API_URL = 'https://soundpods-api.netlify.app';
export const PAYPAL_CLIENT_ID = 'test';

// Product Configuration
export const PRODUCT_ID = 'wireless-earbuds-1';
export const PRODUCT_PRICE = 29.90;

// Shipping Configuration
export const SHIPPING_COST_FR = 4.90;
export const SHIPPING_COST_EU = 9.90;
export const EU_COUNTRIES = ['BE', 'DE', 'ES', 'IT', 'LU', 'NL', 'PT'];

// Email Configuration
export const EMAILJS_SERVICE_ID = 'service_5oc6cg8';
export const EMAILJS_TEMPLATE_ID = 'template_1g76r2n';
export const EMAILJS_PUBLIC_KEY = 'jMFpvXdQYpwP1y5lq';

// PayPal Configuration
export const PAYPAL_OPTIONS = {
  "client-id": "test",
  currency: "EUR",
  components: "buttons",
  "disable-funding": "paylater",
  "enable-funding": "card,paypal",
  intent: "capture"
};