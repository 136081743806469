import React from 'react';
import { useTranslation } from 'react-i18next';
import CartItem from './CartItem';
import { PRODUCT_PRICE } from '../../config/constants';

interface CartItemsProps {
  items: Array<{ id: string; quantity: number }>;
  onUpdateQuantity: (id: string, quantity: number) => void;
  onRemove: (id: string) => void;
}

export default function CartItems({
  items,
  onUpdateQuantity,
  onRemove
}: CartItemsProps) {
  return (
    <div className="bg-white rounded-lg shadow-sm p-6 mb-6">
      {items.map((item) => (
        <CartItem
          key={item.id}
          id={item.id}
          quantity={item.quantity}
          price={PRODUCT_PRICE}
          isProcessing={false}
          onUpdateQuantity={onUpdateQuantity}
          onRemove={onRemove}
        />
      ))}
    </div>
  );
}