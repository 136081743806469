import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useCart } from '../context/CartContext';
import { Check, Star, Truck, Package, Battery, Bluetooth, Wifi, Clock, Award, Zap } from 'lucide-react';
import { PRODUCT_ID, PRODUCT_PRICE } from '../config/constants';

export default function Product() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { addToCart } = useCart();

  const features = [
    {
      icon: <Battery className="h-6 w-6" />,
      title: t('product.features.battery.title'),
      description: t('product.features.battery.description')
    },
    {
      icon: <Bluetooth className="h-6 w-6" />,
      title: t('product.features.bluetooth.title'),
      description: t('product.features.bluetooth.description')
    },
    {
      icon: <Wifi className="h-6 w-6" />,
      title: t('product.features.noise.title'),
      description: t('product.features.noise.description')
    },
    {
      icon: <Zap className="h-6 w-6" />,
      title: t('product.features.charging.title'),
      description: t('product.features.charging.description')
    }
  ];

  const handleAddToCart = () => {
    addToCart(PRODUCT_ID);
    navigate('/cart');
  };

  return (
    <div className="bg-gray-50 min-h-screen py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Trust Badges */}
        <div className="flex flex-wrap justify-center gap-6 mb-12">
          {[
            { icon: <Award className="h-5 w-5" />, text: t('product.badges.quality') },
            { icon: <Clock className="h-5 w-5" />, text: t('product.badges.shipping24h') },
            { icon: <Truck className="h-5 w-5" />, text: t('product.badges.fastDelivery') }
          ].map((badge, index) => (
            <div key={index} className="flex items-center gap-2 bg-white px-4 py-2 rounded-full shadow-sm">
              <span className="text-blue-600">{badge.icon}</span>
              <span className="text-sm font-medium text-gray-700">{badge.text}</span>
            </div>
          ))}
        </div>

        {/* Product Header */}
        <div className="text-center mb-12">
          <div className="inline-flex items-center gap-2 px-4 py-2 bg-blue-50 rounded-full text-blue-700 font-medium mb-6">
            <Zap className="h-4 w-4" />
            <span>{t('product.limitedOffer')}</span>
          </div>
          <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-4">
            {t('product.title')}
          </h1>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            {t('product.description')}
          </p>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-16">
          {/* Image Section */}
          <div>
            <div className="bg-white rounded-3xl overflow-hidden shadow-sm p-8 relative">
              <div className="absolute top-4 left-4 z-10 bg-red-100 text-red-800 px-3 py-1 rounded-full text-sm font-semibold">
                -70%
              </div>
              <img
                src="https://raw.githubusercontent.com/luigirtv/images/refs/heads/main/51jFBGIqtXL._AC_SL1024.webp"
                alt={t('product.title')}
                className="w-full h-auto object-contain"
              />
            </div>
          </div>

          {/* Product Info Section */}
          <div className="space-y-8">
            {/* Price Section */}
            <div className="bg-white rounded-2xl p-8 shadow-sm">
              <div className="flex items-center justify-between mb-4">
                <div className="flex items-center gap-3">
                  <span className="text-5xl font-bold text-gray-900">{t('product.price.current')}</span>
                  <div className="flex flex-col">
                    <span className="text-lg text-gray-500 line-through">{t('product.price.original')}</span>
                    <span className="text-sm text-green-600 font-medium">{t('product.price.save')}</span>
                  </div>
                </div>
              </div>
              <div className="flex items-center gap-2 text-sm text-gray-600 mb-6">
                <div className="flex">
                  {[...Array(5)].map((_, i) => (
                    <Star key={i} className="h-4 w-4 text-yellow-400 fill-current" />
                  ))}
                </div>
                <span className="font-medium">4.8/5</span>
                <span>•</span>
                <span>{t('product.reviews')}</span>
              </div>
              <div className="space-y-4">
                <button
                  onClick={handleAddToCart}
                  className="w-full bg-blue-600 text-white py-4 px-8 rounded-full text-lg font-medium hover:bg-blue-700 transition-colors flex items-center justify-center gap-2"
                >
                  <Package className="h-5 w-5" />
                  {t('product.addToCart')}
                </button>
                <p className="text-center text-sm text-gray-600">
                  {t('product.secure')}
                </p>
              </div>
            </div>

            {/* Stock Status */}
            <div className="bg-green-50 border border-green-200 rounded-xl p-4">
              <div className="flex items-center gap-2 text-green-800">
                <div className="w-2 h-2 bg-green-500 rounded-full"></div>
                <span className="font-medium">{t('product.stock.inStock')}</span>
                <span className="text-sm">• {t('product.stock.shipping')}</span>
              </div>
            </div>

            {/* Features Grid */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {features.map((feature, index) => (
                <div key={index} className="bg-white p-6 rounded-xl shadow-sm">
                  <div className="text-blue-600 mb-3">{feature.icon}</div>
                  <h3 className="font-semibold text-gray-900 mb-1">{feature.title}</h3>
                  <p className="text-sm text-gray-600">{feature.description}</p>
                </div>
              ))}
            </div>

            {/* Features List */}
            <div className="bg-white rounded-xl p-6 shadow-sm">
              <h3 className="font-semibold text-gray-900 mb-4">{t('product.features.title')}</h3>
              <ul className="space-y-3">
                {t('product.features.list', { returnObjects: true }).map((feature: string, index: number) => (
                  <li key={index} className="flex items-start space-x-3">
                    <Check className="h-5 w-5 text-green-500 flex-shrink-0 mt-0.5" />
                    <span className="text-gray-600">{feature}</span>
                  </li>
                ))}
              </ul>
            </div>

            {/* Quality Assurance */}
            <div className="bg-blue-50 rounded-xl p-6">
              <div className="flex items-start gap-4">
                <Award className="h-8 w-8 text-blue-600 flex-shrink-0" />
                <div>
                  <h3 className="font-semibold text-gray-900 mb-2">{t('product.quality.title')}</h3>
                  <p className="text-sm text-gray-600">
                    {t('product.quality.description')}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}