import React from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { Link } from 'react-router-dom';

export default function FAQ() {
  const { t } = useTranslation();
  const [openSection, setOpenSection] = React.useState<string | null>('general');

  const sections = [
    {
      id: 'general',
      title: 'Informations générales',
      questions: [
        {
          q: "Qu'est-ce qui rend les SoundPods Pro uniques ?",
          a: "Les SoundPods Pro se distinguent par leur écran tactile OLED innovant, leur qualité sonore exceptionnelle et leur autonomie de 36 heures. Ils intègrent également la dernière technologie de réduction de bruit active."
        },
        {
          q: "Quelle est l'autonomie des écouteurs ?",
          a: "Les écouteurs offrent jusqu'à 8 heures d'écoute continue avec une seule charge, et jusqu'à 36 heures au total avec le boîtier de charge. Une charge rapide de 5 minutes vous donne 1 heure d'écoute."
        },
        {
          q: "Les écouteurs sont-ils résistants à l'eau ?",
          a: "Oui, les SoundPods Pro sont certifiés IPX5, ce qui les rend résistants aux éclaboussures et à la transpiration. Ils sont parfaits pour le sport et les activités en extérieur."
        }
      ]
    },
    {
      id: 'shipping',
      title: 'Livraison et retours',
      questions: [
        {
          q: "Quels sont les délais de livraison ?",
          a: "Nous expédions sous 24h ouvrées. La livraison prend généralement 2-3 jours ouvrés en France métropolitaine et 3-5 jours pour l'Europe."
        },
        {
          q: "La livraison est-elle gratuite ?",
          a: "Les frais de port sont de 4,90€ pour la France métropolitaine et 9,90€ pour les autres pays européens."
        }
      ]
    },
    {
      id: 'product',
      title: 'Caractéristiques du produit',
      questions: [
        {
          q: "Quelles sont les fonctionnalités de l'écran tactile ?",
          a: "L'écran OLED affiche le niveau de batterie, le statut de connexion, et permet de contrôler la lecture, le volume, et les modes de réduction de bruit d'un simple toucher."
        },
        {
          q: "Les écouteurs sont-ils compatibles avec tous les appareils ?",
          a: "Oui, les SoundPods Pro utilisent le Bluetooth 5.2 et sont compatibles avec iOS, Android, Windows et macOS. Ils peuvent être connectés à deux appareils simultanément."
        },
        {
          q: "Comment fonctionne la réduction de bruit active ?",
          a: "La technologie ANC utilise des microphones avancés pour analyser et neutraliser les bruits ambiants. Trois modes sont disponibles : ANC fort, ANC adaptatif et mode transparence."
        },
        {
          q: "Peut-on personnaliser les commandes tactiles ?",
          a: "Oui, via l'application mobile gratuite, vous pouvez personnaliser les commandes tactiles et ajuster l'égaliseur selon vos préférences."
        }
      ]
    },
    {
      id: 'payment',
      title: 'Paiement et sécurité',
      questions: [
        {
          q: "Quels moyens de paiement acceptez-vous ?",
          a: "Nous acceptons les paiements par PayPal, qui inclut également le paiement par carte bancaire. Tous les paiements sont sécurisés."
        },
        {
          q: "Les paiements sont-ils sécurisés ?",
          a: "Oui, tous nos paiements sont traités par PayPal, leader mondial du paiement en ligne. Vos données bancaires sont totalement sécurisées."
        },
        {
          q: "Puis-je payer en plusieurs fois ?",
          a: "Oui, PayPal propose le paiement en 4 fois sans frais pour les commandes éligibles."
        }
      ]
    },
    {
      id: 'warranty',
      title: 'Garantie et support',
      questions: [
        {
          q: "Quelle est la durée de la garantie ?",
          a: "Les SoundPods Pro sont garantis 2 ans contre tout défaut de fabrication. La batterie est garantie 1 an."
        },
        {
          q: "Comment contacter le support technique ?",
          a: "Notre équipe support est disponible par email 7j/7. Vous pouvez nous contacter via le formulaire de contact ou directement à support@soundpods.com"
        }
      ]
    }
  ];

  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <h1 className="text-3xl font-bold text-center text-gray-900 mb-8">
        Foire aux questions
      </h1>

      <div className="space-y-6">
        {sections.map((section) => (
          <div key={section.id} className="bg-white rounded-lg shadow-sm overflow-hidden">
            <button
              onClick={() => setOpenSection(openSection === section.id ? null : section.id)}
              className="w-full px-6 py-4 flex items-center justify-between bg-gray-50 hover:bg-gray-100 transition-colors"
            >
              <h2 className="text-lg font-semibold text-gray-900">{section.title}</h2>
              {openSection === section.id ? (
                <ChevronUp className="h-5 w-5 text-gray-500" />
              ) : (
                <ChevronDown className="h-5 w-5 text-gray-500" />
              )}
            </button>

            {openSection === section.id && (
              <div className="px-6 py-4 space-y-4">
                {section.questions.map((qa, index) => (
                  <div key={index} className="border-b border-gray-200 last:border-0 pb-4 last:pb-0">
                    <h3 className="text-base font-medium text-gray-900 mb-2">{qa.q}</h3>
                    <p className="text-gray-600">{qa.a}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>

      <div className="mt-12 text-center">
        <p className="text-gray-600">
          Vous ne trouvez pas la réponse à votre question ?
        </p>
        <Link
          to="/contact"
          className="inline-flex items-center mt-4 text-blue-600 hover:text-blue-800"
        >
          Contactez-nous
        </Link>
      </div>
    </div>
  );
}