import emailjs from '@emailjs/browser';

interface OrderDetails {
  orderId: string;
  customerInfo: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    address: string;
    postalCode: string;
    city: string;
    country: string;
  };
  items: Array<{
    name: string;
    quantity: number;
    price: number;
  }>;
  subtotal: number;
  shipping: number;
  discount: number;
  total: number;
  paymentMethod: 'paypal' | 'stripe';
}

const EMAILJS_SERVICE_ID = 'service_5oc6cg8';
const EMAILJS_TEMPLATE_ID = 'template_1g76r2n';
const EMAILJS_PUBLIC_KEY = 'jMFpvXdQYpwP1y5lq';
const ADMIN_EMAIL = 'ricci.luig@hotmail.com';

export const sendOrderConfirmation = async (order: OrderDetails) => {
  const { customerInfo, orderId, items, subtotal, shipping, discount, total, paymentMethod } = order;

  try {
    // Email pour l'administrateur
    await emailjs.send(
      EMAILJS_SERVICE_ID,
      EMAILJS_TEMPLATE_ID,
      {
        to_email: ADMIN_EMAIL,
        order_id: orderId,
        customer_name: `${customerInfo.firstName} ${customerInfo.lastName}`,
        customer_email: customerInfo.email,
        customer_phone: customerInfo.phone,
        shipping_address: `${customerInfo.address}, ${customerInfo.postalCode} ${customerInfo.city}, ${customerInfo.country}`,
        items_list: items.map(item => `${item.name} x${item.quantity}`).join('\n'),
        subtotal: subtotal.toFixed(2),
        shipping_cost: shipping.toFixed(2),
        discount: discount.toFixed(2),
        total: total.toFixed(2),
        payment_method: paymentMethod === 'paypal' ? 'PayPal' : 'Carte bancaire',
        is_admin_notification: true
      },
      EMAILJS_PUBLIC_KEY
    );

    // Email pour le client
    await emailjs.send(
      EMAILJS_SERVICE_ID,
      EMAILJS_TEMPLATE_ID,
      {
        to_email: customerInfo.email,
        order_id: orderId,
        customer_name: `${customerInfo.firstName} ${customerInfo.lastName}`,
        shipping_address: `${customerInfo.address}, ${customerInfo.postalCode} ${customerInfo.city}, ${customerInfo.country}`,
        items_list: items.map(item => `${item.name} x${item.quantity}`).join('\n'),
        subtotal: subtotal.toFixed(2),
        shipping_cost: shipping.toFixed(2),
        discount: discount.toFixed(2),
        total: total.toFixed(2),
        payment_method: paymentMethod === 'paypal' ? 'PayPal' : 'Carte bancaire',
        is_admin_notification: false
      },
      EMAILJS_PUBLIC_KEY
    );
  } catch (error) {
    console.error('Erreur lors de l\'envoi de l\'email de confirmation:', error);
    throw error;
  }
};