import React from 'react';
import { Shield } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import PayPalCheckoutButton from '../PayPalCheckoutButton';
import type { CustomerInfo } from './CustomerInfoForm';
import { SHIPPING_COST_FR, SHIPPING_COST_EU } from '../../config/constants';

interface CartSummaryProps {
  subtotal: number;
  discount: number;
  shipping?: number;
  promoCode: string;
  promoError: string;
  promoApplied: boolean;
  isProcessing: boolean;
  showPaymentButtons?: boolean;
  customerInfo?: CustomerInfo;
  onPromoCodeChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onApplyPromo: () => void;
  onRemovePromo: () => void;
}

export default function CartSummary({
  subtotal,
  discount,
  customerInfo,
  promoCode,
  promoError,
  promoApplied,
  isProcessing,
  showPaymentButtons = false,
  onPromoCodeChange,
  onApplyPromo,
  onRemovePromo,
}: CartSummaryProps) {
  const { t } = useTranslation();

  // Calcul des frais de port en fonction du pays
  const shippingCost = customerInfo?.country === 'FR' ? SHIPPING_COST_FR : SHIPPING_COST_EU;
  const total = subtotal - discount + shippingCost;

  return (
    <div className="bg-white p-6 rounded-lg shadow-sm">
      <h2 className="text-lg font-semibold text-gray-900 mb-4">
        {t('cart.summary')}
      </h2>

      <div className="space-y-4 mb-6">
        <div className="flex justify-between text-gray-600">
          <span>{t('cart.subtotal')}</span>
          <span>{subtotal.toFixed(2)} €</span>
        </div>
        {promoApplied && (
          <div className="flex justify-between text-green-600">
            <span>{t('cart.discount')}</span>
            <span>-{discount.toFixed(2)} €</span>
          </div>
        )}
        <div className="flex justify-between text-gray-600">
          <span>{t('cart.shipping')}</span>
          <span>{shippingCost.toFixed(2)} €</span>
        </div>
        <div className="border-t pt-4">
          <div className="flex justify-between font-semibold text-gray-900">
            <span>{t('cart.total')}</span>
            <span>{total.toFixed(2)} €</span>
          </div>
        </div>
      </div>

      <div className="mb-6">
        <h3 className="text-sm font-medium text-gray-700 mb-2">
          {t('cart.promoCode')}
        </h3>
        <div className="flex space-x-2">
          <input
            type="text"
            value={promoCode}
            onChange={onPromoCodeChange}
            placeholder={t('cart.enterPromoCode')}
            className="flex-1 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            disabled={isProcessing || promoApplied}
          />
          {!promoApplied ? (
            <button
              onClick={onApplyPromo}
              className="px-4 py-2 bg-gray-800 text-white rounded-md hover:bg-gray-700 transition-colors"
              disabled={isProcessing}
            >
              {t('cart.apply')}
            </button>
          ) : (
            <button
              onClick={onRemovePromo}
              className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 transition-colors"
            >
              {t('cart.removePromo')}
            </button>
          )}
        </div>
        {promoError && (
          <p className="text-red-500 text-sm mt-1">{promoError}</p>
        )}
        {promoApplied && (
          <p className="text-green-500 text-sm mt-1">
            {t('cart.promoSuccess')}
          </p>
        )}
      </div>

      {showPaymentButtons && customerInfo && (
        <div className="space-y-4">
          <PayPalCheckoutButton 
            customerInfo={customerInfo}
            total={total}
            subtotal={subtotal}
            shipping={shippingCost}
            discount={discount}
          />
        </div>
      )}

      <div className="mt-6 text-center text-sm text-gray-500">
        <p>{t('cart.securePayment')}</p>
        <div className="flex justify-center items-center space-x-2 mt-2">
          <Shield className="h-5 w-5 text-gray-400" />
          <span>{t('cart.paypalProtection')}</span>
        </div>
      </div>
    </div>
  );
}