import React from 'react';
import { Link } from 'react-router-dom';
import { 
  ChevronRight, 
  Battery, 
  Bluetooth, 
  Star, 
  Shield, 
  Truck, 
  Clock, 
  CreditCard,
  Award,
  ThumbsUp,
  Package,
  CheckCircle2
} from 'lucide-react';
import { useTranslation } from 'react-i18next';

export default function Home() {
  const { t } = useTranslation();

  return (
    <div className="bg-gray-50">
      {/* Hero Section */}
      <section className="relative h-[90vh] flex items-center">
        <div className="absolute inset-0">
          <img
            src="https://raw.githubusercontent.com/luigirtv/images/refs/heads/main/51jFBGIqtXL._AC_SL1024.webp"
            alt={t('home.hero.title')}
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-gradient-to-r from-black/70 to-black/50" />
        </div>
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-2xl">
            <div className="inline-flex items-center bg-red-500 text-white px-4 py-2 rounded-full mb-6 animate-pulse">
              <Clock className="h-4 w-4 mr-2" />
              <span className="text-sm font-medium">Offre limitée -70% • Plus que quelques heures</span>
            </div>
            <h1 className="text-5xl md:text-6xl font-bold text-white mb-6">
              {t('home.hero.title')}
            </h1>
            <p className="text-xl text-gray-200 mb-8">
              {t('home.hero.subtitle')}
            </p>
            <div className="flex flex-col sm:flex-row gap-4">
              <Link
                to="/product"
                className="inline-flex items-center justify-center px-8 py-4 border border-transparent text-lg font-medium rounded-full text-white bg-blue-600 hover:bg-blue-700 transition-colors"
              >
                {t('home.hero.cta')} <ChevronRight className="ml-2 h-5 w-5" />
              </Link>
              <Link
                to="/product"
                className="inline-flex items-center justify-center px-8 py-4 border-2 border-white text-lg font-medium rounded-full text-white hover:bg-white/10 transition-colors"
              >
                29,90 € <span className="ml-2 text-sm line-through">89,90 €</span>
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* Trust Badges Section */}
      <section className="py-8 bg-white border-b">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            <div className="flex items-center justify-center space-x-2 text-gray-600">
              <Truck className="h-6 w-6 text-blue-600" />
              <span className="text-sm font-medium">Livraison 24/48h</span>
            </div>
            <div className="flex items-center justify-center space-x-2 text-gray-600">
              <Shield className="h-6 w-6 text-blue-600" />
              <span className="text-sm font-medium">Paiement 100% sécurisé</span>
            </div>
            <div className="flex items-center justify-center space-x-2 text-gray-600">
              <Package className="h-6 w-6 text-blue-600" />
              <span className="text-sm font-medium">Stock limité</span>
            </div>
            <div className="flex items-center justify-center space-x-2 text-gray-600">
              <Award className="h-6 w-6 text-blue-600" />
              <span className="text-sm font-medium">Qualité premium</span>
            </div>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="py-24 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold text-gray-900 mb-4">
              {t('home.features.title')}
            </h2>
            <p className="text-xl text-gray-600">
              {t('home.features.subtitle')}
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
            <div className="text-center">
              <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-blue-100 mb-6">
                <Battery className="h-8 w-8 text-blue-600" />
              </div>
              <h3 className="text-xl font-semibold mb-4">{t('home.features.battery.title')}</h3>
              <p className="text-gray-600">{t('home.features.battery.description')}</p>
            </div>
            <div className="text-center">
              <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-blue-100 mb-6">
                <Bluetooth className="h-8 w-8 text-blue-600" />
              </div>
              <h3 className="text-xl font-semibold mb-4">{t('home.features.bluetooth.title')}</h3>
              <p className="text-gray-600">{t('home.features.bluetooth.description')}</p>
            </div>
            <div className="text-center">
              <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-blue-100 mb-6">
                <Shield className="h-8 w-8 text-blue-600" />
              </div>
              <h3 className="text-xl font-semibold mb-4">{t('home.features.noise.title')}</h3>
              <p className="text-gray-600">{t('home.features.noise.description')}</p>
            </div>
          </div>
        </div>
      </section>

      {/* Social Proof Section */}
      <section className="py-16 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-8">
            <div className="flex items-center justify-center space-x-2 mb-4">
              <ThumbsUp className="h-6 w-6 text-blue-600" />
              <span className="text-lg font-medium text-gray-900">Plus de 50 000 clients satisfaits</span>
            </div>
            <div className="flex items-center justify-center space-x-1">
              {[...Array(5)].map((_, i) => (
                <Star key={i} className="h-6 w-6 text-yellow-400 fill-current" />
              ))}
            </div>
            <p className="text-lg text-gray-600 mt-2">Note moyenne 4.8/5 basée sur 12 458 avis</p>
          </div>
        </div>
      </section>

      {/* Reviews Section */}
      <section className="py-24 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold text-gray-900 mb-4">
              {t('home.reviews.title')}
            </h2>
            <p className="text-xl text-gray-600">
              {t('home.reviews.subtitle')}
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {[
              {
                name: "Sophie Martin",
                role: "Professionnelle en télétravail",
                content: "Ces écouteurs sont parfaits pour mes appels vidéo. La réduction de bruit est impressionnante.",
                verified: true
              },
              {
                name: "Thomas Dubois",
                role: "Sportif amateur",
                content: "Je les utilise pendant mes séances de sport. La résistance à l'eau est efficace et le maintien excellent.",
                verified: true
              },
              {
                name: "Marie Lambert",
                role: "Étudiante",
                content: "Le rapport qualité-prix est imbattable. L'autonomie est excellente pour mes longues journées.",
                verified: true
              }
            ].map((review, index) => (
              <div key={index} className="bg-white p-8 rounded-2xl shadow-sm">
                <div className="flex mb-4">
                  {[...Array(5)].map((_, i) => (
                    <Star key={i} className="h-5 w-5 text-yellow-400 fill-current" />
                  ))}
                </div>
                <p className="text-gray-600 mb-6">{review.content}</p>
                <div>
                  <p className="font-semibold text-gray-900">{review.name}</p>
                  <p className="text-sm text-gray-500">{review.role}</p>
                  {review.verified && (
                    <div className="flex items-center mt-2 text-green-600 text-sm">
                      <CheckCircle2 className="h-4 w-4 mr-1" />
                      <span>Achat vérifié</span>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Payment Security Section */}
      <section className="py-16 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-white p-6 rounded-xl shadow-sm text-center">
              <Shield className="h-12 w-12 text-blue-600 mx-auto mb-4" />
              <h3 className="text-lg font-semibold mb-2">Paiement sécurisé</h3>
              <p className="text-gray-600">Vos données bancaires sont protégées avec le plus haut niveau de sécurité</p>
            </div>
            <div className="bg-white p-6 rounded-xl shadow-sm text-center">
              <CreditCard className="h-12 w-12 text-blue-600 mx-auto mb-4" />
              <h3 className="text-lg font-semibold mb-2">Moyens de paiement</h3>
              <p className="text-gray-600">PayPal, Visa, Mastercard, American Express acceptés</p>
            </div>
            <div className="bg-white p-6 rounded-xl shadow-sm text-center">
              <Truck className="h-12 w-12 text-blue-600 mx-auto mb-4" />
              <h3 className="text-lg font-semibold mb-2">Livraison rapide</h3>
              <p className="text-gray-600">Expédition sous 24h et livraison en 48h</p>
            </div>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-24 bg-blue-600">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <div className="inline-flex items-center bg-blue-500 text-white px-4 py-2 rounded-full mb-6">
            <Clock className="h-4 w-4 mr-2" />
            <span className="text-sm font-medium">Offre limitée -70% • Plus que quelques heures</span>
          </div>
          <h2 className="text-4xl font-bold text-white mb-6">
            {t('home.cta.title')}
          </h2>
          <p className="text-xl text-blue-100 mb-8">
            {t('home.cta.subtitle')}
          </p>
          <div className="flex flex-col sm:flex-row justify-center gap-4">
            <Link
              to="/product"
              className="inline-flex items-center px-8 py-4 border border-transparent text-lg font-medium rounded-full text-blue-600 bg-white hover:bg-gray-100 transition-colors"
            >
              Commander maintenant <ChevronRight className="ml-2 h-5 w-5" />
            </Link>
            <div className="flex items-center justify-center text-white">
              <span className="text-2xl font-bold">29,90 €</span>
              <span className="ml-2 text-lg line-through text-blue-200">89,90 €</span>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}