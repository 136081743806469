import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useCart } from '../context/CartContext';
import CartItems from '../components/cart/CartItems';
import CartSummary from '../components/cart/CartSummary';
import CheckoutForm from '../components/cart/CheckoutForm';
import type { CustomerInfo } from '../components/cart/CustomerInfoForm';
import { PRODUCT_PRICE, SHIPPING_COST_FR, SHIPPING_COST_EU } from '../config/constants';

export default function Cart() {
  const { t } = useTranslation();
  const { cartItems, updateQuantity, removeFromCart } = useCart();
  const [promoCode, setPromoCode] = useState('');
  const [promoApplied, setPromoApplied] = useState(false);
  const [promoError, setPromoError] = useState('');
  const [showPaymentButtons, setShowPaymentButtons] = useState(false);
  const [formErrors, setFormErrors] = useState<Record<string, boolean>>({});
  const navigate = useNavigate();
  
  const [customerInfo, setCustomerInfo] = useState<CustomerInfo>({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: '',
    postalCode: '',
    city: '',
    country: 'FR'
  });

  const subtotal = cartItems.reduce((sum, item) => sum + item.quantity * PRODUCT_PRICE, 0);
  const discount = promoApplied ? subtotal * 0.1 : 0;
  const shippingCost = customerInfo.country === 'FR' ? SHIPPING_COST_FR : SHIPPING_COST_EU;
  const total = subtotal - discount + shippingCost;

  const handlePromoCode = () => {
    const cleanPromoCode = promoCode.trim().toUpperCase();
    if (cleanPromoCode === 'PROMO10') {
      setPromoApplied(true);
      setPromoError('');
    } else {
      setPromoError(t('cart.invalidPromo'));
      setPromoApplied(false);
    }
  };

  const handleRemovePromo = () => {
    setPromoApplied(false);
    setPromoCode('');
    setPromoError('');
  };

  const validateForm = () => {
    const errors: Record<string, boolean> = {};
    const fields = ['firstName', 'lastName', 'email', 'phone', 'address', 'postalCode', 'city'] as const;
    
    fields.forEach(field => {
      if (!customerInfo[field]) {
        errors[field] = true;
      }
    });

    if (customerInfo.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(customerInfo.email)) {
      errors.email = true;
    }

    if (customerInfo.phone && !/^\+?[\d\s-]{8,}$/.test(customerInfo.phone)) {
      errors.phone = true;
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleCustomerInfoSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      setShowPaymentButtons(true);
    }
  };

  if (cartItems.length === 0) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">{t('cart.empty')}</h2>
          <p className="text-gray-600 mb-8">{t('cart.emptyMessage')}</p>
          <button
            onClick={() => navigate('/product')}
            className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
          >
            {t('cart.backToProducts')}
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <h1 className="text-3xl font-bold text-gray-900 mb-8">
        {t('cart.title')}
      </h1>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
        <div className="lg:col-span-2">
          <CartItems
            items={cartItems}
            onUpdateQuantity={updateQuantity}
            onRemove={removeFromCart}
          />
          
          <CheckoutForm
            customerInfo={customerInfo}
            formErrors={formErrors}
            showPaymentButtons={showPaymentButtons}
            onCustomerInfoChange={setCustomerInfo}
            onSubmit={handleCustomerInfoSubmit}
          />
        </div>

        <div>
          <CartSummary
            subtotal={subtotal}
            discount={discount}
            shipping={shippingCost}
            total={total}
            promoCode={promoCode}
            promoError={promoError}
            promoApplied={promoApplied}
            isProcessing={false}
            showPaymentButtons={showPaymentButtons}
            customerInfo={customerInfo}
            onPromoCodeChange={(e) => setPromoCode(e.target.value)}
            onApplyPromo={handlePromoCode}
            onRemovePromo={handleRemovePromo}
          />
        </div>
      </div>
    </div>
  );
}